//config.js
import Amplify, { XR } from 'aws-amplify';
import mySceneConfig from './sumerian-exports.js'; // This file will be generated by the Sumerian AWS Console 


let config = {
	earnestHealthURI: "https://us-east-1.sumerian.aws/b61d1c7302d848c5b688e32a0497c07b.scene",
	region: 'us-east-1', 
  userPoolId: 'us-east-1_giefNzNQ5',
  userPoolWebClientId: '6etgdo7un0mmajekf9k47besrh',  
  bucket: 'arn:aws:s3:::kiosk-login-bucket',
  bucketRegion: 'us-east-1',
	appIdPinpoint: '19943cce163e41a3bed1fcd6552ac852',
	regionPinpoint: 'us-east-1',
  sceneName: 'ERNEST HEALTH',
  sceneDivId: "sumerian-scene-dom-id",
	bufferSize: 1000,
	flushInterval: 5000, // 5s 
	flushSize: 100,
	resendLimit: 5,
  headerTimeout: 1000, //1s
  POST_DELAY: 15000 //15s
};

let configureAmplify = async () => {
   Amplify.configure({
    Auth: {
        region: config.region, 
        userPoolId: config.userPoolId,
        userPoolWebClientId: config.userPoolWebClientId, 
        mandatorySignIn: true,
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'us-east-1:58f61877-e182-4ca6-a190-42c6d45fa7d9',
        identityPoolRegion: config.region        
    }, 

    Storage: {
      bucket: config.bucket,
      region: config.bucketRegion
    },
/*
    Analytics: {
      disabled: false,
      autoSessionRecord: true,

      AWSPinpoint: {
        appId:config.appIdPinpoint,
        region: config.regionPinpoint,

        bufferSize: config.bufferSize,
        flushInterval: config.flushInterval, // 5s 
        flushSize: config.flushSize,
        resendLimit: config.resendLimit
    } //end Pinpoint
  }, //end Analytics

    XR: {
      SumerianProvider: { // Sumerian specific configuration
        region: 'us-east-1', // Sumerian region 
        scenes: { 
          "ERNEST HEALTH": { // Friendly scene name
            sceneConfig: mySceneConfig // Scene configuration from Sumerian publish
          }
        },
      }   
    } //end XR */
  }); //end Amplify.configure()

  // //configure XR
  XR.configure({ // XR category configuration
    SumerianProvider: { // Sumerian specific configuration
      region: 'us-east-1', // Sumerian region 
      scenes: { 
        "ERNEST HEALTH": { // Friendly scene name
          sceneConfig: mySceneConfig // Scene configuration from Sumerian publish
        }
      },
    }
  });
  console.log(mySceneConfig)
 }; //end config()

export default config;
export { configureAmplify }; 