//AppIframe.js
//
import React, {Component} from 'react';
import './App.css';
import config  from './spaConfig.js';
import { XR } from 'aws-amplify';
//import mySceneConfig from './sumerian-exports.js'; // This file will be generated by the Sumerian AWS Console 

async function loadAndStartScene() {
  try {
      await XR.loadScene("ERNEST HEALTH", config.sceneDivId);
      XR.start("ERNEST HEALTH");
    } catch (e) {
      console.log("Error in loadAndStartScene, error: ", e, e.stack);
    }
  }   

export default class AppIframe extends Component {
  getInitialState() {
    return {       
      //url: config.earnestHealthURI,
      authed: false,
      sumerianLoaded: false,
      authData: null      
    };
  }

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    console.log("in App constructor, this.state:", this.state);

  } //end ctor  


 

  componentDidMount() {
    loadAndStartScene();
  }

  componentDidUpdate() {
    if (XR.isSceneLoaded(config.sceneName)) {
      if (XR.isMuted(config.sceneName)) {      // The scene is muted
          XR.setMuted(config.sceneName, false) // Unmute
      }
    }
  }

  render() {
    return (
      <div id={"sumerian-scene-dom-id"} className="App-scene"></div>
    ); 
  }

}; //end class AppIframe