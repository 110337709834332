import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import registerServiceWorker from './registerServiceWorker';
import { withAuthenticator } from 'aws-amplify-react'; // import Iframe from 'react-iframe';

// const AppWithAuth = withAuthenticator(App, {includeGreetings:true});
const AppWithAuth = withAuthenticator(App);

/*
const federated = {
	facebook_app_id: '425307177950154',
	amazon_client_id: 'amzn1.application-oa2-client.4c5550581c704afb9eabe5b6d07a716b'
};
*/

const app = <AppWithAuth />;

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
