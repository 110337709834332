//App.js
import React, {Component} from 'react';
//import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { configureAmplify } from './spaConfig.js';

import { Auth, Hub } from 'aws-amplify';

import AuthHandler from './authHandler.js';

import AppHeader from './AppHeader.jsx';
import AppIframe from './AppIframe.js';
//import Federate from './Federate.js';

var AWS = require('aws-sdk');

configureAmplify();

export default class App extends Component {
  getInitialState() {
    return {
      headerTimeout: 1000, //1 seconds
      //url: config.earnestHealthURI,
      sess: Auth.currentSession(),
      on: true,
      authed: false,
      sumerianLoaded: false,
      authData: null
    }; 
  }

  constructor(props) {
    super(props);
    console.log("in App constructor, props:", props);
    this.state = this.getInitialState();
    this.state.authData = this.props.authData;
    this.authHandler = new AuthHandler();
    Hub.listen('auth', this, 'App');

    console.log("in App constructor, this.state:", this.state);

    console.log("[App.js - INFO] starting iot stuff")
    new AWS.Iot();
    
    //var iotData = new AWS.IotData({endpoint: 'a1l7mlginwb96q-ats.iot.us-east-1.amazonaws.com'});
    
    //console.log("[App.js - INFO] IoT Data Endpoint: " + JSON.stringify(iotData.endpoint, null, 3))
    
    new AWS.Polly();
    new AWS.LexRuntime();

    // this.postTokens = this.postTokens.bind(this);
  } //end ctor 

  toggleHeader() {
    this.setState({on: !this.state.on }); //toggle 
  }

  beginTimeout() {
    setTimeout( () => this.toggleHeader(), this.state.headerTimeout)
  }

  componentDidMount() {
    this.setState({authData: this.props.authData});
  } 


  render() { 
    return ( 
      <div className="App">
          <AppHeader /> 
          <AppIframe />
      </div>
    );
  }
}
