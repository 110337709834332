import { Hub, Logger } from 'aws-amplify';
import { Component } from 'react';

export default class AuthHandler extends Component {
	getDefaultProps() {
		return {
			logger: new Logger('AuthHandler')
		};
	}

    constructor(props) {
    	super(props);
    	this.state = { logger: new Logger('AuthHandler') };
        Hub.listen('auth', this, 'AuthHandler');
    }

	onAuthEvent(payload) {
		// let sceneIframe = document.getElementById('sceneFrame');
		console.log("in onAuthEvent, payload:", payload);
		let frame = document.getElementsByTagName("iframe")[0];

		this.state.logger.error("posting auth'd message...")
	    console.log("posting authorized message...");
	    window.postMessage("authorized", frame.contentWindow);

	};

    // Default handler for listening events
    onHubCapsule(capsule) {
        const { channel, payload } = capsule;
        if (channel === 'auth') { this.onAuthEvent(payload).bind(this); }
    }	
};


// let postTokens = async (tokens) => {
//     // let frameWindow = document.getElementById('sceneFrame').contentWindow;
//     let frameWindow = window.parent;

//     frameWindow.postMessage(tokens, '*');
// };

// export { postTokens };